import { useState } from 'react';
import { useAuthStore } from '@/stores/auth';

function Counter() {
  const [count, setCount] = useState(0);
  const { token, checkAuth, login, logout } = useAuthStore();

  return (
    <div className="card">
      <button type="button" onClick={() => setCount((cnt) => cnt + 1)}>
        count is {count}
      </button>
      {checkAuth() && <p>Token: {token}</p>}
      <button type="button" onClick={() => login('asasd')}>
        Login
      </button>
      <button type="button" onClick={() => logout()}>
        Logout
      </button>
    </div>
  );
}

export default Counter;
