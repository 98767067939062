import { format } from 'date-fns';

export function roundPrecision(num: number, precision: number) {
  const factor = 10 ** precision;
  return Math.round(num * factor) / factor;
}

export function floorPrecision(num: number, precision: number) {
  const factor = 10 ** precision;
  return Math.floor(num * factor) / factor;
}

export function formatDateDot(date: Date) {
  return format(date, 'yyyy.MM.dd');
}
