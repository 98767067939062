import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useImmer } from 'use-immer';
import classNames from 'classnames';
import { FilterSettingModal } from './FilterSettingModal';
import FilterIcon from '@/assets/icons/filter.svg';
import FilterResetIcon from '@/assets/icons/filter-reset.svg';
import './FilterSearch.scss';

type CheckSetting = {
  label: string;
  value: boolean;
};
export type FilterSetting = {
  from: Date | null;
  to: Date | null;
  confirm: [CheckSetting, CheckSetting];
  review: [CheckSetting, CheckSetting];
  payment: [CheckSetting, CheckSetting];
  militaryBranch: CheckSetting[];
};

const militaryBranches = [
  '육군',
  '해군',
  '공군',
  '국직',
  '해병대',
  '의무 경찰',
  '의무 소방',
  '사회복무요원',
  '기타',
];

export function FilterSearch() {
  const [modalOpen, setModalOpen] = useState(false);
  const initialFilter: FilterSetting = {
    from: null,
    to: null,
    confirm: [
      {
        label: '승인',
        value: false,
      },
      {
        label: '미승인',
        value: false,
      },
    ],
    review: [
      {
        label: '확인',
        value: false,
      },
      {
        label: '미확인',
        value: false,
      },
    ],
    payment: [
      {
        label: '미지급',
        value: false,
      },
      {
        label: '지급 완료',
        value: false,
      },
    ],
    militaryBranch: militaryBranches.map((v) => ({
      label: v,
      value: false,
    })),
  };
  const [filter, setFilter] = useImmer(initialFilter);
  const filterTags = useMemo<string[]>(() => {
    const tags = [];
    if (filter.from && filter.to) {
      tags.push(
        `${format(filter.from, 'yyyy.MM.dd')}~${format(
          filter.to,
          'yyyy.MM.dd',
        )}`,
      );
    }

    tags.push(
      ...(['confirm', 'review', 'payment', 'militaryBranch'] as const).flatMap(
        (key) => filter[key].filter((v) => v.value).map((v) => v.label),
      ),
    );
    return tags;
  }, [filter]);

  return (
    <div className="card filter-search">
      <div className="card-header">
        <div className="card-header-title">필터 검색</div>
        <button
          className="btn-filter-reset"
          type="button"
          onClick={() => {
            setFilter(initialFilter);
          }}
        >
          <FilterResetIcon />
          필터 초기화
        </button>
      </div>
      <div className="card-body">
        <button
          type="button"
          className={classNames('filter-tags', {
            active: filterTags.length,
          })}
          onClick={() => setModalOpen(true)}
        >
          <FilterIcon />
          {filterTags.length ? (
            filterTags.map((tag) => (
              <div className="filter-tag" key={tag}>
                {tag}
              </div>
            ))
          ) : (
            <div className="filter-tag">필터 옵션을 설정해주세요.</div>
          )}
        </button>
        <input
          type="text"
          className="input-search"
          placeholder="검색어를 입력하세요."
        />
        <FilterSettingModal
          initialFilter={initialFilter}
          filter={filter}
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          setFilter={setFilter}
        />
      </div>
    </div>
  );
}
