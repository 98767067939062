import classNames from 'classnames';
import './Select.scss';

// FIXME
export default function Select(props: {
  className?: string;
  options: {
    value: string | number;
    label: string;
  }[];
}) {
  const { options, className } = props;
  return (
    <select className={classNames('custom-select', className)}>
      {options.map((option) => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </select>
  );
}
