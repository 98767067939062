import './IconButton.scss';

export function IconButton(
  props: {
    icon: React.ReactElement;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>,
) {
  const { icon, ...rest } = props;

  return (
    <button type="button" className="icon-btn" {...rest}>
      {icon}
    </button>
  );
}
