import { useCallback, useRef } from 'react';
import IvViewer from '@/assets/libs/iv-viewer/iv-viewer';
import '@/assets/libs/iv-viewer/iv-viewer.min.css';
import './ImageViewer.scss';
import { BoundingPoly } from '@/@types/common';

type ImageViewerOptions = {
  boundingPolygons: (BoundingPoly & { confidence: number })[];
};

export function useImageViewer(
  src: string,
  options: ImageViewerOptions = { boundingPolygons: [] },
) {
  const iv = useRef<IvViewer>();
  const zoom = useRef(100);
  const { boundingPolygons } = options;

  const containerRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (iv.current) {
        iv.current.destroy();
        iv.current = undefined;
      }
      if (node && !iv.current) {
        iv.current = new IvViewer(node, {
          listeners: {
            onZoomChange: ({ zoomValue }) => {
              if (zoomValue) {
                zoom.current = zoomValue;
              }
            },
            onImageLoaded: ({ image }) => {
              const ctx = image.getContext('2d')!;
              boundingPolygons.forEach((poly) => {
                const { confidence, min_x, min_y, max_x, max_y } = poly;

                if (confidence === 0) {
                  return;
                }

                ctx.beginPath();
                ctx.lineWidth = 1;
                const color = confidence >= 0.5 ? '#02866F' : '#E84242';
                ctx.strokeStyle = color;
                ctx.fillStyle = `${color}33`; // 20% opacity
                ctx.rect(min_x, min_y, max_x - min_x, max_y - min_y);
                ctx.stroke();
                ctx.fill();
              });
            },
          },
        });
        iv.current.load(src);
      }
    },
    [src],
  );

  return {
    zoomIn: () => {
      if (iv.current) {
        iv.current.zoom(zoom.current + 50);
      }
    },
    zoomOut: () => {
      if (iv.current) {
        iv.current.zoom(zoom.current - 50);
      }
    },
    viewer: (
      <div
        id="image-container"
        style={{
          height: '100%',
        }}
        ref={containerRef}
      />
    ),
  };
}
