import { Link, Navigate, type RouteObject } from 'react-router-dom';
import AuthGuard from './interceptors/AuthGuard';
// Pages
import Test from '@/pages/test/Test';
import Test2 from '@/pages/test2/Test2';
import Protected from '@/pages/protected/Protected';
import NotFound from '@/pages/not-found/NotFound';
import DesktopLayout from '@/layouts/DesktopLayout';
import ClaimsBatch from '@/pages/claims/ClaimsBatch';
import ClaimsDetail from '@/pages/claims/ClaimsDetail';
import IntegrationList from '@/pages/integration/IntegrationList';
import IntegrationDetail from '@/pages/integration/IntegrationDetail';

export interface RouteHandle {
  requiresAuth?: boolean;
  lnb?: {
    to: string;
    label: string;
  }[];
}

export const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <div>
        <AuthGuard>
          <DesktopLayout />
        </AuthGuard>
      </div>
    ),
    children: [
      { index: true, element: <Navigate to="/home" replace /> },

      {
        path: '/home',
        element: (
          <div id="layout-main">
            <div id="layout-main-content">
              <Link to="/claims">{'->'} 청구 현황 관리</Link>
            </div>
          </div>
        ),
      },
      {
        path: '/claims',
        children: [
          { index: true, element: <Navigate to="/claims/batch" replace /> },
          { path: 'batch', element: <ClaimsBatch /> },
          {
            path: 'detail',
            element: <ClaimsDetail />,
          },
        ],
        handle: {
          lnb: [
            { to: '/claims/batch', label: '청구 현황 전체 관리' },
            { to: '/claims/detail', label: '청구 현황 개별 관리' },
          ],
        },
      },
      {
        path: '/integration',
        children: [
          { index: true, element: <IntegrationList /> },
          {
            path: ':id',
            element: <IntegrationDetail />,
          },
        ],
        handle: {
          lnb: [{ to: '/integration', label: '송수신 자료 목록 조회' }],
        },
      },
      // { path: '/policy', element: <ClaimsBatch /> },
      // { path: '/board', element: <ClaimsBatch /> },
      // { path: '/preference', element: <ClaimsBatch /> },

      /**
       * NOTE: for Test
       */
      { path: '/login', element: <div>login</div> },
      { path: '/test', element: <Test /> },
      { path: '/test2', element: <Test2 /> },
      {
        path: '/protected',
        handle: {
          requiresAuth: true,
        },
        element: <Protected />,
      },

      { path: '*', element: <NotFound /> },
    ],
  },
];
