import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

const axiosConfig: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  // timeout: ,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
  },
};

export const instance = axios.create(axiosConfig);
