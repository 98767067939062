import { useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { FilterSearch } from '@/components/filter/FilterSearch';
import './ClaimsDetail.scss';
import { Column } from '@/components/layout/Column';
import { Button } from '@/components/button/Button';
// import MemoIcon from '@/assets/icons/memo.svg';
import DownloadIcon from '@/assets/icons/download.svg';
import { Row } from '@/components/layout/Row';
import { getBillingDetail, getBillings } from '@/api/billings';
import { getBase64Img } from '@/utils/parse';
import { Billing } from '@/@types/billing';
import { ReceiptViewer } from '@/views/receipt-viewer/ReceiptViewer';
import { floorPrecision, roundPrecision } from '@/utils/format';
import { ClaimerInfo } from '@/views/claimer-info/ClaimerInfo';
import ArrowPrevIcon from '@/assets/icons/arrow-prev.svg';
import ArrowNextIcon from '@/assets/icons/arrow-next.svg';
import { Checkbox } from '@/components/checkbox/Checkbox';

type BillingField<T extends keyof Billing = keyof Billing> =
  T extends keyof Billing
    ? {
        label: string;
        field: T;
        transform: (_v: Billing[T]) => string;
      }
    : never;

const claimListFields: BillingField[] = [
  {
    label: '청구 일자',
    field: 'billing_dt',
    transform: (v) => format(parseISO(v), 'yyyy/MM/dd'),
  },
  { label: '접수 번호', field: 'billing_no', transform: (v) => v.slice(0, 8) },
  { label: '군번', field: 'mltry_no', transform: (v) => v },
  { label: '청구자', field: 'patient_name', transform: (v) => v },
  { label: '병원명', field: 'hospital_name', transform: (v) => v },
  {
    label: '본인 부담금',
    field: 'out_of_pocket_expenses',
    transform: (v) => v.toLocaleString(),
  },
  {
    label: '실 지급액',
    field: 'sum_of_paid_expenses',
    transform: (v) => v.toLocaleString(),
  },
  {
    label: '진료 일자',
    field: 'billing_no', // FIXME
    transform: (_v) => '?',
  },
  {
    label: '병적 번호',
    field: 'mltry_rgst_no',
    transform: (v) => v,
  },
  { label: '군별', field: 'mltry_grp', transform: (v) => v },
  {
    label: '병원 유형',
    field: 'hospital_classification',
    transform: (v) => v,
  },
  {
    label: '진료비 총액',
    field: 'sum_of_medical_expenses',
    transform: (v) => v.toLocaleString(),
  },
  {
    label: '지급 예정 총액',
    field: 'billing_no', // FIXME
    transform: (_v) => '?',
  },
  {
    label: '평균 신뢰도',
    field: 'mean_confidence',
    transform: (v) => roundPrecision(v, 2).toString(),
  },
];

const labelMap = {
  patient_name: '환자 성명',
  patient_registration_number: '환자등록번호',
  ptnt_cls: '환자 구분',
  hospital_classification: '병원 유형', // 병원 구분
  hospital_registration_number: '사업자등록번호',
  medical_department: '진료과목',
  drg_no: '질병군(DRG)번호',
  duration_of_treatment: '진료기간',
  med_bill_no: '영수증번호',
  pckt_pymnt_tot: '급여_본인부담금합계',
  sum_of_medical_expenses: '진료비총액',
  out_of_pocket_expenses: '환자부담총액',
  sum_of_paid_expenses: '납부한금액_합계',
  full_pckt_pymnt_tot: '전액본인부담금 합계',
  hospital_name: '병원명',
};

// function ClaimList() {
//   return (
//     <Column className="card claim-list">
//       {new Array(10).fill(0).map((_, i) => (
//         <div className="claim-item">
//           <div className="claim-item-header">
//             <div className="claim-date">
//               <span className="claim-date-label">청구 일자: </span>
//               <span className="claim-date-value">2021/01/01</span>
//             </div>
//             <Button lightgray md onClick={() => {}}>
//               접수
//             </Button>
//           </div>
//           <div className="claim-item-body">
//             <div className="receipt-thumbnail">
//               <img src={sampleImg} alt="" />
//             </div>
//             <div className="claim-info">
//               {dummy.map(([label, value]) => (
//                 <div className="claim-info-item" key={label}>
//                   <span className="claim-info-item-label">{label}: </span>
//                   <span className="claim-info-item-value">{value}</span>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       ))}
//     </Column>
//   );
// }

function ClaimsDetail() {
  const [page, setPage] = useState<number>(1);
  const [selectedBilling, setSelectedBilling] = useState<string>();
  const {
    isLoading: isLoadingList,
    isFetching: isFetchingList,
    // error,
    data: { billings, page: billingsPage } = {},
  } = useQuery({
    // TODO: add more filter values as queryKey
    queryKey: ['claims', page],
    queryFn: () =>
      getBillings({
        from: '2023-03-01T00:00:00+09:00',
        to: '2024-03-20T00:00:00+09:00',
        page,
      }),
    select: (res) => res.data,
    refetchInterval: Number(
      import.meta.env.VITE_BILLING_LIST_POLLING_INTERVAL_MS,
    ),
  });

  useEffect(() => {
    if (
      !selectedBilling ||
      billings?.every((b) => b.billing_no !== selectedBilling)
    ) {
      setSelectedBilling(billings?.[0].billing_no);
    }
  }, [billings]);

  const {
    isLoading: isLoadingDetail,
    isFetching: isFetchingDetail,
    isPreviousData: isPreviousDetail,
    // error,
    data: billingDetail,
  } = useQuery({
    queryKey: ['claims-detail', selectedBilling],
    queryFn: () => getBillingDetail(selectedBilling!),
    select: (res) => res.data.billing,
    enabled: !!selectedBilling,
    keepPreviousData: true,
  });

  const sumOfMedicalExpenses = Number(
    billingDetail?.receipts.ocrText.find(
      (item) => item.label === 'sum_of_medical_expenses',
    )?.value,
  );
  const outOfPocketExpenses = Number(
    billingDetail?.receipts.ocrText.find(
      (item) => item.label === 'out_of_pocket_expenses',
    )?.value,
  );

  return (
    <Row fill h="fill" gap={10}>
      <Column w="420" gap={15} scroll>
        <FilterSearch />
        {/* <ClaimList /> */}
        <Column gap={12} h="fill" className="claim-list-container" scroll>
          <Column
            h="fill"
            className={classNames('card claim-list', {
              loading: isLoadingList,
              fetching: isFetchingList,
            })}
          >
            <div className="claim-list-header">
              <div className="claim-list-master-check">
                <Checkbox
                  checked={false}
                  onChange={() => {}}
                  label={
                    <span className="pl(10) font(14/10) 500 c(#888)">
                      전체 선택
                    </span>
                  }
                />
              </div>
            </div>
            <Column h="fill" scroll>
              {billings?.map((billing) => (
                <button
                  type="button"
                  onClick={() => setSelectedBilling(billing.billing_no)}
                  key={billing.billing_no}
                  className={classNames('claim-item', {
                    selected: selectedBilling === billing.billing_no,
                  })}
                >
                  <div className="claim-item-header">
                    <Checkbox checked={false} onChange={() => {}} />
                    <div className="claim-no">No.?</div>
                    {/* <>
                                <Button
                                  className="process-btn rejected-2"
                                  lightgray
                                  md
                                  onClick={() => setSelected(i)}
                                >
                                  최종 불가
                                </Button>
                                <button
                                  style={{ lineHeight: 0 }}
                                  type="button"
                                  onClick={() => {}}
                                >
                                  <MemoIcon />
                                </button>
                                <div className="rejection-reason">중복 신청</div>
                              </> */}
                    <div className="process-status requested">자격 확인 중</div>
                  </div>
                  <div className="claim-item-body">
                    <div className="receipt-thumbnail">
                      <img src={getBase64Img(billing.thumbnail_image)} alt="" />
                    </div>
                    <div className="claim-info">
                      {claimListFields.map(({ label, field, transform }) => (
                        <div className="claim-info-item" key={label}>
                          <span className="claim-info-item-label">
                            {label}:{' '}
                          </span>
                          <span className="claim-info-item-value">
                            {billing[field]
                              ? (
                                  transform as (
                                    _v: Billing[typeof field],
                                  ) => string
                                )(billing[field])
                              : '-'}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="claim-item-footer">
                    <div className="claim-date">
                      <span className="claim-date-label">수정 일자: </span>
                      <span className="claim-date-value">?</span>
                    </div>
                  </div>
                </button>
              ))}
            </Column>
          </Column>
          <ReactPaginate
            className="pagination"
            pageClassName="page-item"
            previousClassName="previous-page"
            nextClassName="next-page"
            pageCount={billingsPage?.totalPages || 0}
            onPageChange={({ selected }) => setPage(selected + 1)}
            pageRangeDisplayed={5}
            previousLabel={<ArrowPrevIcon />}
            nextLabel={<ArrowNextIcon />}
          />
        </Column>
      </Column>
      <Column w="430" gap={15} className="pb(10)">
        {/* FIXME: card component as layout */}
        <div
          className={classNames('card', {
            loading: isLoadingDetail,
            fetching: isFetchingDetail,
            previous: isPreviousDetail,
          })}
          style={{ flex: 1, overflow: 'initial' }}
        >
          {billingDetail && <ReceiptViewer detail={billingDetail} />}
        </div>
      </Column>
      <Column w="460" gap={20} className="pb(10)">
        <div
          className={classNames('card receipt-ocr-text', {
            loading: isLoadingDetail,
            fetching: isFetchingDetail,
            previous: isPreviousDetail,
          })}
        >
          <div className="card-header">
            <button type="button">
              <ArrowPrevIcon />
            </button>
            <div className="card-header-title">
              <div className="card-header-title-text">영수증 전처리 텍스트</div>
              <div className="card-header-title-filename">
                2023.08.24_23_12345678.json
              </div>
            </div>
            <button type="button">
              <DownloadIcon />
            </button>
            <button type="button">
              <ArrowNextIcon />
            </button>
          </div>
          <div className="card-body">
            {billingDetail?.receipts.ocrText.map(
              ({ label, value, confidence }) => (
                <div className="ocr-field" key={label}>
                  <div className="ocr-field-label">{labelMap[label]}</div>
                  <div className="ocr-field-value">{value}</div>
                  <div
                    className={classNames('ocr-field-confidence', {
                      low: confidence <= 0.3,
                    })}
                  >
                    {floorPrecision(confidence, 1)}
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </Column>
      <Column w="260" gap={10} className="pb(10)">
        <div
          className={classNames('card card-sm total-bill', {
            loading: isLoadingDetail,
            fetching: isFetchingDetail,
            previous: isPreviousDetail,
          })}
        >
          <div className="card-header">
            <div className="card-header-title">진료비 및 지급 금액</div>
          </div>
          <div className="card-body p(15) vbox gap(20)">
            <div className="bill-item">
              <span className="bill-item-label">진료비 총액 : </span>
              <span className="bill-item-value">
                ￦ {(sumOfMedicalExpenses || '-').toLocaleString()}
              </span>
            </div>
            <div className="bill-item">
              <span className="bill-item-label">청구비 지원 합산 금액 : </span>
              <span className="bill-item-value">￦ -</span>
            </div>
            <div className="bill-item">
              <span className="bill-item-label">환자 부담 총액 : </span>
              <span className="bill-item-value">
                ￦ {(outOfPocketExpenses || '-').toLocaleString()}
              </span>
            </div>
            <div className="bill-item">
              <span className="bill-item-label">청구자 지급 가능 금액 : </span>
              <span className="bill-item-value">￦ -</span>
            </div>
          </div>
          <div className="card-footer total-amount">
            <span className="total-amount-label 500">지급 금액 : </span>
            <span className="total-amount-value 700">￦ -</span>
          </div>
        </div>
        <div
          className={classNames('card', {
            loading: isLoadingDetail,
            fetching: isFetchingDetail,
            previous: isPreviousDetail,
          })}
          style={{ overflow: 'initial' }}
        >
          <ClaimerInfo detail={billingDetail} />
        </div>
        <div className="process-btns vbox gap(12)">
          <Button className="process-btn approve" onClick={() => {}}>
            승인
          </Button>
          <Button className="process-btn reject" onClick={() => {}}>
            거절
          </Button>
          <Button className="process-btn submit" onClick={() => {}}>
            제출
          </Button>
        </div>
      </Column>
    </Row>
  );
}

export default ClaimsDetail;
