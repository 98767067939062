import { addDays, endOfToday, startOfDay, sub } from 'date-fns';
import { ko } from 'date-fns/locale';
import {
  RangeKeyDict,
  DateRangePicker as ReactDateRangePicker,
  createStaticRanges,
} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// 일주일,1개월,3개월,6개월,1년,2년,3년
const ranges = [
  {
    label: '1주일',
    value: { weeks: 1 },
  },
  {
    label: '1개월',
    value: { months: 1 },
  },
  {
    label: '3개월',
    value: { months: 3 },
  },
  {
    label: '6개월',
    value: { months: 6 },
  },
  {
    label: '1년',
    value: { years: 1 },
  },
  {
    label: '2년',
    value: { years: 2 },
  },
  {
    label: '3년',
    value: { years: 3 },
  },
].map((range) => ({
  label: range.label,
  range: () => ({
    startDate: startOfDay(addDays(sub(Date.now(), range.value), 1)),
    endDate: endOfToday(),
  }),
}));

const staticRanges = createStaticRanges(ranges);

export default function DateRangePicker(props: {
  handleSelect: (_ranges: RangeKeyDict) => void;
  startDate: Date;
  endDate: Date;
}) {
  const { handleSelect, startDate, endDate } = props;
  const selectionRange = {
    startDate,
    endDate,
    key: 'selection',
  };
  return (
    <ReactDateRangePicker
      locale={ko}
      ranges={[selectionRange]}
      onChange={handleSelect}
      staticRanges={staticRanges}
      inputRanges={[]}
    />
  );
}
