function NotFound() {
  return (
    <div id="layout-main">
      <div id="layout-main-content">
        <h2> Not Found </h2>
      </div>
    </div>
  );
}

export default NotFound;
