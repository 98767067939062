import { NavLink, useMatches } from 'react-router-dom';
import classNames from 'classnames';
import { useMemo } from 'react';

export type LocalRoute = {
  to: string;
  label: string;
};

export default function Lnb() {
  const matches = useMatches();

  const handle = useMemo(() => {
    const matchedHandle = matches.find((m) => {
      const h = m.handle as { lnb?: LocalRoute[] } | undefined;
      return Boolean(h?.lnb);
    });
    return matchedHandle?.handle as { lnb?: LocalRoute[] } | undefined;
  }, [matches]);

  return (
    <div className="lnb-container">
      {handle?.lnb?.map((route) => (
        <NavLink
          to={route.to}
          key={route.to}
          className={({ isActive }) =>
            classNames({
              'lnb-nav-link': true,
              active: isActive,
            })
          }
        >
          {route.label}
        </NavLink>
      ))}
    </div>
  );
}
