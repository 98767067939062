import classNames from 'classnames';
import { useRef, useState } from 'react';
import { endOfToday, startOfToday } from 'date-fns';
import { Range } from 'react-date-range';
import { useClickAway } from 'react-use';
import { Button } from '@/components/button/Button';
import DateRangePicker from '@/components/date-range-picker/DateRangePicker';
import { Column } from '@/components/layout/Column';
import { Row } from '@/components/layout/Row';
import Spacer from '@/components/layout/Spacer';
import CalendarIcon from '@/assets/icons/calendar.svg';
import { formatDateDot } from '@/utils/format';
import Select from '@/components/select/Select';
import './IntegrationList.scss';
import Pagination from '@/components/pagination/Pagination';

function TabButton(
  props: React.ComponentProps<typeof Button> & { active: boolean },
) {
  return Button({
    ...props,
    className: classNames('px(25) h(36) font(14) 700', {
      'c(#02866F) bg(#fff) b(#02866F)': !props.active,
    }),
  });
}

type Tab = 'send' | 'receive';

const dummyData = [
  [
    1,
    16548317,
    '김철수',
    '자격 확인 중',
    '760,060원',
    '2023/10/18 20:19:54',
    '2023/10/18 20:19:54',
  ],
  [
    2,
    12887221,
    '홍길동',
    '자격 확인 중',
    '1,010,010원',
    '2023/10/13 14:34:05',
    '2023/10/13 14:34:05',
  ],
  [
    3,
    35325115,
    '이영희',
    '자격 확인 중',
    '168,510원',
    '2023/10/10 10:41:57',
    '2023/10/10 10:41:57',
  ],
  [
    4,
    12994151,
    '홍길동',
    '제출',
    '47,880원',
    '2023/10/09 11:21:31',
    '2023/10/09 11:21:31',
  ],
  [
    5,
    15489221,
    '이영희',
    '제출',
    '271,140원',
    '2023/10/06 07:06:41',
    '2023/10/06 07:06:41',
  ],
  [
    6,
    13676932,
    '김철수',
    '제출',
    '111,240원',
    '2023/10/02 20:19:54',
    '2023/10/02 20:19:54',
  ],
  [
    7,
    11898521,
    '박영철',
    '제출',
    '1,210,110원',
    '2023/10/01 14:34:05',
    '2023/10/01 14:34:05',
  ],
  [
    8,
    12985155,
    '김철수',
    '제출',
    '231,180원',
    '2023/09/28 10:41:57',
    '2023/09/28 10:41:57',
  ],
  [
    9,
    13254789,
    '홍길동',
    '제출',
    '345,180원',
    '2023/09/24 11:21:31',
    '2023/09/24 11:21:31',
  ],
  [
    10,
    16611457,
    '박철수',
    '제출',
    '210,000원',
    '2023/09/21 07:06:41',
    '2023/09/21 07:06:41',
  ],
];

function DataRow(item: (string | number)[]) {
  const props = [...item];
  return (
    // eslint-disable-next-line react/destructuring-assignment
    <tr className="table-row" key={item[0]}>
      {props.map((d) => (
        <td className="body-item w(1%)">{d}</td>
      ))}
    </tr>
  );
}

function SearchBar() {
  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: startOfToday(),
    endDate: endOfToday(),
  });
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>('send');
  const search = () => {
    console.log('search');
  };

  const dateRangePickerRef = useRef<HTMLDivElement>(null);
  useClickAway(dateRangePickerRef, () => {
    setOpenDateRangePicker(false);
  });

  return (
    <Row>
      <Row gap={20}>
        <TabButton
          onClick={() => setActiveTab('send')}
          active={activeTab === 'send'}
        >
          송신 조회
        </TabButton>
        <TabButton
          onClick={() => setActiveTab('receive')}
          active={activeTab === 'receive'}
        >
          수신 조회
        </TabButton>
      </Row>
      <Spacer />
      <Row gap={20}>
        <Row gap={10} className="relative">
          <div className="font(14/10) c(#02866F) 500">적재 일자</div>
          <button
            type="button"
            onClick={() => setOpenDateRangePicker(!openDateRangePicker)}
            className="h(30) r(4) b(#02866F) p(3/8) hbox gap(5) bg(#fff)"
          >
            <CalendarIcon />
            <div className="c(#02866F) font(14/10/1.4) 500">
              {formatDateDot(dateRange.startDate)} ~{' '}
              {formatDateDot(dateRange.endDate)}
            </div>
          </button>
          <div className="layer(bottom:10+outside)" ref={dateRangePickerRef}>
            {openDateRangePicker && (
              <DateRangePicker
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                handleSelect={(ranges) => {
                  const { startDate, endDate } =
                    ranges.selection as Required<Range>;
                  setDateRange({ startDate, endDate });
                }}
              />
            )}
          </div>
        </Row>
        <Row gap={10}>
          <Select
            className="w(128) h(30)"
            options={[
              { value: '1', label: '이름' },
              { value: '2', label: '?' },
              { value: '3', label: '?' },
            ]}
          />
          <input
            type="text"
            className="input-search"
            placeholder="검색어를 입력하세요."
          />
        </Row>
        <Button onClick={search} className="w(70) h(30) font(14)">
          조회하기
        </Button>
      </Row>
    </Row>
  );
}

function IntegrationList() {
  return (
    <Column>
      <SearchBar />
      <table className="data-table-2 mt(24)">
        <thead className="table-header">
          <th className="header-item">번호</th>
          <th className="header-item">청구 번호</th>
          <th className="header-item">청구자명</th>
          <th className="header-item">청구 상태</th>
          <th className="header-item">지급 예상액</th>
          <th className="header-item">청구 일시</th>
          <th className="header-item">적재 일시</th>
        </thead>
        <tbody
          className={classNames('table-body', {
            // 'loading h(1250)': isLoadingList,
            // previous: isPreviousList,
          })}
        >
          {dummyData.map((b) => DataRow(b))}
        </tbody>
      </table>
      <Row className="mt(20) h(30) relative" center>
        <Select
          className="absolute x(0) h(30)"
          options={[
            { value: 10, label: '10개씩 보기' },
            { value: 30, label: '30개씩 보기' },
            { value: 50, label: '50개씩 보기' },
            { value: 100, label: '100개씩 보기' },
          ]}
        />
        <Pagination
          totalPages={10}
          onPageChange={({ selected }) => {}}
          pageRangeDisplayed={10}
        />
      </Row>
    </Column>
  );
}

export default IntegrationList;
