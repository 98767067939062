import { Billing } from '@/@types/billing';
import { BillingDetail } from '@/@types/billing-detail';
import { Page } from '@/@types/common';
import { instance } from '@/utils/axios-instance';

// FIXME: receive Date type
export function getBillings(queryKey: {
  from: string;
  to: string;
  page: number;
}) {
  return instance.get<{
    billings: Billing[];
    page: Page;
  }>('/billings', {
    params: {
      date_from: queryKey.from,
      date_to: queryKey.to,
      page: queryKey.page,
      size: 10,
    },
  });
}

export function getBillingDetail(billingNo: string) {
  return instance.get<{
    billing: BillingDetail;
  }>(`/billings/${billingNo}`);
}
