import { AutoLayout } from './AutoLayout';

export function Row(props: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  id?: string;
  className?: string;

  w?: string | number;
  h?: string | number;

  p?: string | number;
  gap?: string | number;
  flex?: string | boolean;
  hbox?: boolean | string;

  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  center?: boolean;
  right?: boolean;
  fill?: boolean;
  pack?: boolean;

  scroll?: boolean;
}) {
  const { children } = props;

  return <AutoLayout {...props}>{children}</AutoLayout>;
}

Row.defaultProps = {
  w: undefined,
  h: undefined,
  id: undefined,
  className: undefined,
  flex: undefined,
  hbox: true,
  gap: undefined,
  p: undefined,
  top: undefined,
  bottom: undefined,
  left: undefined,
  center: undefined,
  right: undefined,
  fill: undefined,
  pack: undefined,
  scroll: undefined,
  style: {},
};
