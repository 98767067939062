import { NavLink, Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { createBreakpoint } from 'react-use';
import Lnb from './Lnb';
import ContentArea, { contentAreaClass } from './ContentArea';
// import PersonIcon from '@/assets/icons/person_black_24dp.svg';
// import LogoutIcon from '@/assets/icons/logout_black_24dp.svg';
import AssignmentIcon from '@/assets/icons/assignment_black_24dp.svg';
import NewFolderIcon from '@/assets/icons/create_new_folder_black_24dp.svg';
import HomeIcon from '@/assets/icons/home_black_24dp.svg';
import PollIcon from '@/assets/icons/poll_black_24dp.svg';
import ReceiptIcon from '@/assets/icons/receipt_long_black_24dp.svg';
import SettingsIcon from '@/assets/icons/settings_black_24dp.svg';
import { Column } from '@/components/layout/Column';

function NavLinkIcon({ to, icon }: { to: string; icon: JSX.Element }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames({
          'nav-link-icon': true,
          active: isActive,
        })
      }
    >
      {icon}
    </NavLink>
  );
}

const useBreakpoint = createBreakpoint({
  xl: 1650,
  lg: 0,
});

export default function DesktopLayout() {
  const breakpoint = useBreakpoint();

  return (
    <div
      id="root-layout"
      className={classNames('desktop', {
        xl: breakpoint === 'xl',
        lg: breakpoint === 'lg',
      })}
    >
      <div id="layout-header">
        <h1 className="header-title">직접 청구 관리 시스템</h1>
        {/* <div className="header-actions">
          <button type="button">
            <PersonIcon />
          </button>
          <button type="button">
            <LogoutIcon />
          </button>
        </div> */}
      </div>
      <div id="layout-body">
        <div id="layout-sidebar">
          <NavLinkIcon to="/home" icon={<HomeIcon />} />
          <NavLinkIcon to="/claims" icon={<ReceiptIcon />} />
          <NavLinkIcon to="/integration" icon={<PollIcon />} />
          <NavLinkIcon to="/policy" icon={<NewFolderIcon />} />
          <NavLinkIcon to="/board" icon={<AssignmentIcon />} />
          <NavLinkIcon to="/preference" icon={<SettingsIcon />} />
        </div>
        <Column id="layout-content">
          <div id="layout-lnb">
            <ContentArea>
              <Lnb />
            </ContentArea>
          </div>
          <Column id="layout-main" w="fill" h="fill" scroll>
            <Column
              p="20/0/10"
              h="fill"
              id="layout-main-content"
              className={contentAreaClass}
            >
              <Outlet />
            </Column>
          </Column>
        </Column>
      </div>
    </div>
  );
}
