import classNames from 'classnames';

export function Button(props: {
  //   type: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  children: React.ReactNode;
  className?: string;
  onClick: () => void;
  loading?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  full?: boolean;
  lightgray?: boolean;
  gray?: boolean;
}) {
  const {
    children,
    className,
    onClick,
    loading,
    sm,
    md,
    lg,
    xl,
    full,
    lightgray,
    gray,
  } = props;

  return (
    <button
      type="button"
      className={classNames('btn', className, {
        'btn-sm': sm,
        'btn-md': md,
        'btn-lg': lg,
        'btn-xl': xl,
        'btn-full': full,
        'btn-lightgray': lightgray,
        'btn-gray': gray,
      })}
      onClick={onClick}
    >
      {loading ? 'TBD: loading' : children}
    </button>
  );
}

Button.defaultProps = {
  className: '',
  loading: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  full: false,
  lightgray: false,
  gray: false,
};
