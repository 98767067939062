import { combine, devtools, persist } from 'zustand/middleware';
import { create } from 'zustand';

export const useAuthStore = create(
  devtools(
    persist(
      combine(
        {
          token: null as string | null,
        },
        (set, get) => ({
          setToken: (token: string | null) => set(() => ({ token })),
          login: (token: string) => set(() => ({ token })),
          logout: () => set(() => ({ token: null })),
          checkAuth: () => !!get().token,
        }),
      ),
      { name: 'authStore' },
    ),
  ),
);
