import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import router from './router/index';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

if (import.meta.env.DEV) {
  (async () => {
    const [{ persistQueryClient }, { createSyncStoragePersister }] =
      await Promise.all([
        import('@tanstack/react-query-persist-client'),
        import('@tanstack/query-sync-storage-persister'),
      ]);

    // NOTE: 개발 모드에서 API 결과를 local storage에 캐시
    const localStoragePersister = createSyncStoragePersister({
      storage: localStorage,
    });

    persistQueryClient({
      queryClient,
      persister: localStoragePersister,
    });
    // eslint-disable-next-line no-console
  })().catch(console.error);
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
