import classNames from 'classnames';

export function AutoLayout(props: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  id?: string;
  className?: string;

  w?: string | number;
  h?: string | number;

  hbox?: string | boolean;
  vbox?: string | boolean;

  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  center?: boolean;
  right?: boolean;
  fill?: boolean;
  pack?: boolean;

  p?: string | number;
  gap?: string | number;
  flex?: string | boolean;
  scroll?: boolean;
}) {
  const { children, id, className, w, h, p, gap, flex, scroll, style } = props;

  const { hbox, vbox } = props;
  const { left, center, right, top, bottom, pack, fill } = props;
  const hPos = Object.entries({ left, center, right }).find(([, v]) => v)?.[0];
  const vPos = Object.entries({ top, bottom }).find(([, v]) => v)?.[0];
  const pos =
    Object.entries({ pack, fill }).find(([, v]) => v)?.[0] ??
    [vPos, hPos].filter((v) => v).join('+');

  const [boxName, boxValue] = Object.entries({ hbox, vbox }).find(
    ([, v]) => v,
  ) ?? ['hbox', true];

  return (
    <div
      id={id}
      style={style}
      className={classNames(className, {
        [`${boxName}(${boxValue})`]: typeof boxValue === 'string',
        [`${boxName}(${pos})`]: typeof boxValue !== 'string' && pos,
        [`${boxName}`]: typeof boxValue !== 'string' && !pos,
        [`flex(${flex})`]: flex,
        [`p(${p})`]: p,
        [`gap(${gap})`]: gap,
        [`w(${w})`]: w,
        [`h(${h})`]: h,
        scroll,
      })}
    >
      {children}
    </div>
  );
}

AutoLayout.defaultProps = {
  style: {},
  hbox: undefined,
  vbox: undefined,
  top: undefined,
  bottom: undefined,
  left: undefined,
  center: undefined,
  right: undefined,
  fill: undefined,
  pack: undefined,
  w: undefined,
  h: undefined,
  id: undefined,
  className: undefined,
  flex: undefined,
  gap: undefined,
  p: undefined,
  scroll: undefined,
};
