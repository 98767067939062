import { useLocation, useMatches, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { RouteHandle } from '../routes';
import { useAuthStore } from '@/stores/auth';

export default function AuthGuard({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const matches = useMatches();
  const navigate = useNavigate();
  const { checkAuth } = useAuthStore();

  useEffect(() => {
    console.debug('route changed:', location.pathname);
    if (
      matches.some(({ handle }) => (handle as RouteHandle)?.requiresAuth) &&
      !checkAuth()
    ) {
      navigate(`/login?redirect=${location.pathname}`, { replace: true });
    }
  }, [matches]);

  return children;
}
