import ReactPaginate from 'react-paginate';
import ArrowPrevIcon from '@/assets/icons/arrow-prev.svg';
import ArrowNextIcon from '@/assets/icons/arrow-next.svg';
import './Pagination.scss';

export default function Pagination(props: {
  totalPages: number | undefined;
  onPageChange: (_e: { selected: number }) => void;
  pageRangeDisplayed?: number;
}) {
  const { totalPages = 0, onPageChange, pageRangeDisplayed = 5 } = props;

  return (
    <ReactPaginate
      className="pagination"
      pageClassName="page-item"
      previousClassName="previous-page"
      nextClassName="next-page"
      pageCount={totalPages}
      onPageChange={onPageChange}
      pageRangeDisplayed={pageRangeDisplayed}
      previousLabel={<ArrowPrevIcon />}
      nextLabel={<ArrowNextIcon />}
    />
  );
}
