import Modal from 'react-modal';
import { useImmer } from 'use-immer';
import './FilterSettingModal.scss';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Duration,
  addDays,
  isEqual,
  sub,
  subDays,
  format,
  parse,
  isValid,
  startOfToday,
} from 'date-fns';
import { Checkbox } from '../checkbox/Checkbox';
import { CheckboxGroup } from '../checkbox/CheckboxGroup';
import { Button } from '../button/Button';
import { FilterSetting } from './FilterSearch';
import FilterResetIcon from '@/assets/icons/filter-reset.svg';

const customStyles = {
  overlay: {
    // backdrop
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 100,
  },
  content: {
    width: '530px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'none',
    padding: 0,
  },
};

const dateRangeButtons = [
  {
    label: '오늘',
    value: {
      days: 1,
    },
  },
  {
    label: '1주',
    value: {
      weeks: 1,
    },
  },
  {
    label: '1개월',
    value: {
      months: 1,
    },
  },
  {
    label: '3개월',
    value: {
      months: 3,
    },
  },
];

function CustomDateInput(props: {
  date: Date | null;
  onChange: (_date: Date) => void;
}) {
  const { date, onChange } = props;
  const original = date ? format(date, 'yyyy.MM.dd') : null;
  const [text, setText] = useState(original);
  useEffect(() => {
    setText(original);
  }, [original]);

  function onBlur(e: React.FocusEvent<HTMLInputElement>) {
    const d = parse(e.target.value, 'yyyyMMdd', new Date());
    if (isValid(d)) {
      onChange(d);
      setText(format(d, 'yyyy.MM.dd'));
    } else {
      setText(original);
    }
  }

  return (
    <input
      type="text"
      className="date-range-input"
      maxLength={8}
      value={text ?? ''}
      placeholder={original ?? ''}
      onChange={(e) => {
        setText(e.target.value.replace(/[^0-9]/g, ''));
      }}
      onFocus={() => {
        setText('');
      }}
      onBlur={onBlur}
    />
  );
}

export function FilterSettingModal(props: {
  initialFilter: FilterSetting;
  filter: FilterSetting;
  setFilter: (_filter: FilterSetting) => void;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { initialFilter, filter, isOpen, onClose, setFilter } = props;

  const [draft, setDraft] = useImmer(initialFilter);
  useEffect(() => {
    setDraft({ ...filter });
  }, [filter, isOpen]);

  const isActiveRange = (duration: Duration) => {
    return (
      draft.from &&
      draft.to &&
      isEqual(sub(startOfToday(), duration), subDays(draft.from, 1)) &&
      isEqual(startOfToday(), draft.to)
    );
  };

  const saveSetting = () => {
    setFilter({ ...draft });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="modal-wrapper filter-setting-modal">
        <div className="modal-header">
          <div className="modal-header-title"> 필터 설정 </div>
          <button
            className="btn-filter-reset"
            type="button"
            onClick={() => {
              setDraft(initialFilter);
            }}
          >
            <FilterResetIcon />
            필터 초기화
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-body-item">
            <div className="modal-body-item-label">조회 기간</div>
            <div className="modal-body-item-content">
              <div className="date-range-buttons">
                {dateRangeButtons.map((button) => (
                  <button
                    type="button"
                    className={classNames('date-range-button', {
                      active: isActiveRange(button.value),
                    })}
                    key={button.label}
                    onClick={() => {
                      setDraft((prev) => {
                        prev.from = addDays(
                          sub(startOfToday(), button.value),
                          1,
                        );
                        prev.to = startOfToday();
                      });
                    }}
                  >
                    {button.label}
                  </button>
                ))}
              </div>
              <div className="date-range-custom">
                <CustomDateInput
                  date={draft.from}
                  onChange={(d) =>
                    setDraft((prev) => {
                      prev.from = d;
                    })
                  }
                />
                <span className="range-symbol"> - </span>
                <CustomDateInput
                  date={draft.to}
                  onChange={(d) =>
                    setDraft((prev) => {
                      prev.to = d;
                    })
                  }
                />

                <span className="hint">입력 예시) 20011231</span>
              </div>
            </div>
          </div>
          <div className="modal-body-item">
            <div className="modal-body-item-label">승인 상태</div>
            <div className="modal-body-item-content">
              <CheckboxGroup>
                {draft.confirm.map((check, index) => (
                  <Checkbox
                    key={check.label}
                    checked={check.value}
                    onChange={(v) => {
                      setDraft((prev) => {
                        prev.confirm[index].value = v;
                      });
                    }}
                    label={check.label}
                  />
                ))}
              </CheckboxGroup>
            </div>
          </div>
          <div className="modal-body-item">
            <div className="modal-body-item-label">확인 상태</div>
            <div className="modal-body-item-content">
              <CheckboxGroup>
                {draft.review.map((check, index) => (
                  <Checkbox
                    key={check.label}
                    checked={check.value}
                    onChange={(v) => {
                      setDraft((prev) => {
                        prev.review[index].value = v;
                      });
                    }}
                    label={check.label}
                  />
                ))}
              </CheckboxGroup>
            </div>
          </div>
          <div className="modal-body-item">
            <div className="modal-body-item-label">지급 상태</div>
            <div className="modal-body-item-content">
              <CheckboxGroup>
                {draft.payment.map((check, index) => (
                  <Checkbox
                    key={check.label}
                    checked={check.value}
                    onChange={(v) => {
                      setDraft((prev) => {
                        prev.payment[index].value = v;
                      });
                    }}
                    label={check.label}
                  />
                ))}
              </CheckboxGroup>
            </div>
          </div>
          <div className="modal-body-item">
            <div
              className="modal-body-item-label"
              style={{
                verticalAlign: 'top',
              }}
            >
              군별
            </div>
            <div className="modal-body-item-content">
              <CheckboxGroup>
                {draft.militaryBranch.map((check, index) => (
                  <Checkbox
                    key={check.label}
                    checked={draft.militaryBranch[index].value}
                    onChange={(v) => {
                      setDraft((prev) => {
                        prev.militaryBranch[index].value = v;
                      });
                    }}
                    label={check.label}
                  />
                ))}
              </CheckboxGroup>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal-footer-actions">
            <Button md lightgray onClick={onClose}>
              취소
            </Button>
            <Button md onClick={() => saveSetting()}>
              등록
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
