/* eslint-disable react/destructuring-assignment */
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';
import { Button } from '@/components/button/Button';
import { Checkbox } from '@/components/checkbox/Checkbox';
import { FilterSearch } from '@/components/filter/FilterSearch';
import { Column } from '@/components/layout/Column';
import { Row } from '@/components/layout/Row';
import './ClaimsBatch.scss';
import MemoIcon from '@/assets/icons/memo-gray.svg';
import { getBillings } from '@/api/billings';
import { Billing } from '@/@types/billing';
import { getBase64Img } from '@/utils/parse';
import { floorPrecision } from '@/utils/format';
import Pagination from '@/components/pagination/Pagination';

function Description(props: {
  label: string;
  value: string;
  size?: number;
  h?: number;
  labelColor?: string;
  color?: string;
}) {
  const { label, value, size = 14, h = 10, labelColor = '#777', color } = props;

  return (
    <div className={`description-item hbox >font(${size}/${h})+500+h(10)`}>
      <div className={`c(${color ?? labelColor}) pre`}>{label}: </div>
      <div className={color ? `c(${color})` : ''}> {value}</div>
    </div>
  );
}

function SelectionStats(props: { targets?: Billing[] }) {
  const { targets } = props;

  const dummyCount = '??';
  return (
    <div className="card w(600) vbox">
      <div className="card-header hbox h(30)! px(20)! font(13/9) 500">
        <div className="">선택 진행 상황 통계</div>
        <div className="">선택: {targets?.length}건</div>
      </div>
      <div className="card-body hbox(fill) h(fill)">
        <div className="w(fill) pack br(1/#d0d0d0)">
          <div className="vbox gap(15)">
            <Description
              label="자격 확인 중"
              value={`${dummyCount}개`}
              color="#888"
            />
            <Description
              label="자격 미달"
              value={`${dummyCount}개`}
              color="#000"
            />
            <Description
              label="검토 중"
              value={`${dummyCount}개`}
              color="#888"
            />
          </div>
        </div>
        <div className="w(fill) pack br(1/#d0d0d0)">
          <div className="vbox gap(15)">
            <Description
              label="승인"
              value={`${dummyCount}개`}
              color="#3268CC"
            />
            <Description
              label="거절"
              value={`${dummyCount}개`}
              color="#E84242"
            />
            <Description
              label="제출"
              value={`${dummyCount}개`}
              color="#02866F"
            />
            <Description
              label="거절 완료"
              value={`${dummyCount}개`}
              color="#E84242"
            />
          </div>
        </div>
        <div className="w(fill) pack">
          <div className="vbox gap(15)">
            <Description label="지급 대기" value={`${dummyCount}개`} />
            <Description
              label="지급 완료"
              value={`${dummyCount}개`}
              color="#3268CC"
            />
            <Description
              label="지급 거절"
              value={`${dummyCount}개`}
              color="#E84242"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ClaimRow(
  billing: Billing,
  checks: Map<string, boolean>,
  checkOne: (key: string, value: boolean) => void,
) {
  return (
    <Row className="table-row" key={billing.billing_no}>
      <div className="body-item w(109) p(13/15/12) vbox(center) gap(13)">
        <Row w="fill">
          <Checkbox
            checked={checks.get(billing.billing_no) ?? false}
            onChange={(v) => checkOne(billing.billing_no, v)}
            label={<span className="pl(8) font(12/12) 500">No.??</span>}
          />
        </Row>
        <div className="receipt-thumbnail w(50) h(70)">
          <img src={getBase64Img(billing.thumbnail_image)} alt="" />
        </div>
      </div>
      <div className="body-item w(300)">
        <div className="vbox gap(18)">
          <Description
            label="청구 일자"
            value={format(parseISO(billing.billing_dt), 'yyyy/MM/dd')}
          />
          <Description label="진료 일자" value="????/??/??" />
          <Description label="접수 번호" value="??" />
          <Description label="병적 번호" value={billing.mltry_rgst_no} />
        </div>
      </div>
      <div className="body-item w(260)">
        <Column gap={23} scroll={false}>
          <Description label="군번" value={billing.mltry_no} />
          <Description label="군구분" value={billing.mltry_cls} />
          <Description label="청구자" value={billing.user_nm} />
        </Column>
      </div>
      <div className="body-item w(230)">
        {/* 병원 유형: 상급 종합병원
              병원명: 서울 종합병원 */}
        <Column gap={23} scroll={false}>
          <Description
            label="병원 유형"
            value={billing.hospital_classification}
          />
          <Description label="병원명" value={billing.hospital_name} />
        </Column>
      </div>
      <div className="body-item w(310)">
        {/* 진료비 총액: 151,000
본인 부담금: 21,000
지급 예정 금액: 21,000
실 지급액: 0 */}
        <Column gap={18} scroll={false}>
          <Description label="진료비 총액" value="151,000" />
          <Description label="본인 부담금" value="21,000" />
          <Description label="지급 예상 금액" value="21,000" />
          <Description label="실 지급액" value="0" />
        </Column>
      </div>
      <div className="body-item w(139)">
        {/* NOTE: 0.33 같은 경우에는 0.3으로 표시되면서 검은 글씨라는 문제가 있다 */}
        <div
          className={classNames('font(18) 700', {
            'c(#e84242)': billing.mean_confidence <= 0.3,
          })}
        >
          {floorPrecision(billing.mean_confidence, 1).toString()}
        </div>
      </div>
      <div className="body-item vbox w(250) h(fill) relative">
        <Column center w="fill" h="fill" className="p(15/14)" gap={33}>
          <Row w="fill" right scroll={false}>
            <Description label="수정 일자" value="??" size={12} h={9} />
          </Row>
          <div className="w(hug) h(30) r(4) p(10/7) font(14/10) 400 c(#888) bg(#eee)">
            자격 확인 중
          </div>
          <div className="layer(bottom:10+right:10) pack">
            <MemoIcon />
          </div>
        </Column>
      </div>
    </Row>
  );
}

export default function ClaimsBatch() {
  const [page, setPage] = useState<number>(1);
  const {
    isLoading: isLoadingList,
    isFetching: isFetchingList,
    isPreviousData: isPreviousList,
    // error,
    data: { billings, page: billingsPage } = {},
  } = useQuery({
    // TODO: add more filter values as queryKey
    queryKey: ['claims', page],
    queryFn: () =>
      getBillings({
        // FIXME: use filter values
        from: '2023-03-01T00:00:00+09:00',
        to: '2024-03-20T00:00:00+09:00',
        page,
      }),
    select: (res) => res.data,
    cacheTime: 0,
    keepPreviousData: true,
    // refetchInterval: Number(
    // import.meta.env.VITE_BILLING_LIST_POLLING_INTERVAL_MS,
    // ),
  });

  // #region checkbox
  const [checks, setChecks] = useState(new Map<string, boolean>());
  const checkedBillingNo = [...checks.keys()].filter((k) => checks.get(k));
  const mastercheck =
    checkedBillingNo.length === billings?.length && checks.size > 0;
  const checkOne = useCallback(
    (key: string, value: boolean) => {
      checks.set(key, value);
      setChecks(new Map(checks));
    },
    [checks],
  );
  const checkMaster = useCallback(
    (value: boolean) => {
      billings?.forEach((b) => checks.set(b.billing_no, value));
      setChecks(new Map(checks));
    },
    [checks, billings],
  );
  useEffect(() => {
    checks.clear();
    setChecks(new Map(checks));
  }, [page]);
  // #endregion

  return (
    <Column gap={15}>
      <Row fill gap={20}>
        <div className="w(450)">
          <FilterSearch />
        </div>
        <SelectionStats
          targets={billings?.filter((b) => checks.get(b.billing_no))}
        />
        <Column w={510} fill gap={15}>
          <div className="card">
            <div className="card-header h(30)! font(13/9) 500">
              선택 청구 지급 금액
            </div>
            <div className="card-body h(50) p(20) grid(2) pre font(14/10) 400">
              <div>총 진료비 : {'  '}￦ 0 </div>
              <div>총 지급액 : {'  '}￦ 0 </div>
            </div>
          </div>
          <Row fill gap={17} className="process-btns">
            <button type="button">현황 엑셀 다운로드</button>
            <Button className="process-btn approve w(fill)" onClick={() => {}}>
              승인
            </Button>
            <Button className="process-btn reject w(fill)" onClick={() => {}}>
              거절
            </Button>
            <Button className="process-btn submit w(fill)" onClick={() => {}}>
              제출
            </Button>
          </Row>
        </Column>
      </Row>
      <div className="data-table">
        <div className="table-header">
          <div className="header-item w(109) pl(15) gap(auto)!">
            <Checkbox
              disabled={isFetchingList}
              checked={mastercheck}
              onChange={(v) => {
                checkMaster(v);
              }}
            />
            <span>영수증</span>
          </div>
          <div className="header-item w(300)">번호</div>
          <div className="header-item w(260)">개인 정보</div>
          <div className="header-item w(230)">병원</div>
          <div className="header-item w(310)">진료비</div>
          <div className="header-item w(139)">평균 신뢰도</div>
          <div className="header-item w(250)">진행 상태</div>
          <div className="w(110)" />
        </div>
        <div
          className={classNames('table-body', {
            'loading h(1250)': isLoadingList,
            previous: isPreviousList,
          })}
        >
          <Column>{billings?.map((b) => ClaimRow(b, checks, checkOne))}</Column>
        </div>
        <div className="my(20)">
          <Pagination
            totalPages={billingsPage?.totalPages}
            onPageChange={({ selected }) => setPage(selected + 1)}
            pageRangeDisplayed={10}
          />
        </div>
      </div>
    </Column>
  );
}
