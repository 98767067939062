export function Checkbox(props: {
  checked: boolean;
  onChange: (_checked: boolean) => void;
  // string or ReactNode
  label?: string | React.ReactNode;
  disabled?: boolean;
}) {
  const { checked, onChange, label, disabled } = props;

  return (
    <label className="checkbox-container">
      <input
        className="checkbox-input"
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(event) => onChange(event.target.checked)}
      />
      <span className="checkmark" />
      {label && typeof label === 'string' ? (
        <span className="checkbox-label-text">{label}</span>
      ) : (
        label
      )}
    </label>
  );
}
