import { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { BillingDetail } from '@/@types/billing-detail';
import './ClaimerInfo.scss';
import ArrowPrevIcon from '@/assets/icons/arrow-prev.svg';
import ArrowNextIcon from '@/assets/icons/arrow-next.svg';
import { IconButton } from '@/components/button/IconButton';
import { roundPrecision } from '@/utils/format';
import { getBase64Img } from '@/utils/parse';

export function ClaimerInfo(props: { detail: BillingDetail | undefined }) {
  const { detail } = props;
  const [historyPage, setHistoryPage] = useState(1);
  useEffect(() => {
    setHistoryPage(1);
  }, [detail]);

  if (detail === undefined) {
    return null;
  }

  const descriptions = [
    {
      label: '청구자',
      value: detail.user_nm,
    },
    {
      label: '군번',
      value: detail.mltry_no,
    },
    {
      label: '병적 번호',
      value: detail.mltry_rgst_no,
    },
    {
      label: '병원명',
      value: detail.receipts.ocrText.find((o) => o.label === 'hospital_name')
        ?.value,
    },
  ];

  const totalPages = detail.claimHistory.length;
  const historyItem = detail.claimHistory[historyPage - 1];

  return (
    <div className="claimer-info">
      <div className="info-section">
        <div className="section-header">
          <div className="section-title">청구자 개인정보</div>
        </div>
        <div className="section-body descriptions">
          {descriptions.map((o) => (
            <div className="desc-field" key={o.label}>
              <div className="desc-label">{o.label}: </div>
              <div className="desc-value">{o.value ?? '-'}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="info-section">
        <div className="section-header">
          <div className="section-title">청구자 과거 이력</div>
          <div className="section-page">
            <div className="page-nums">
              <span className="current-page">{historyPage}</span>
              <span className="total-pages"> / {totalPages}</span>
            </div>
          </div>
        </div>
        <div className="section-body claim-histories relative">
          <IconButton
            className="absolute(0,center)"
            icon={<ArrowPrevIcon />}
            onClick={() => setHistoryPage((p) => p - 1)}
            disabled={historyPage === 1}
          />
          <IconButton
            className="absolute(~0,center)"
            icon={<ArrowNextIcon />}
            onClick={() => setHistoryPage((p) => p + 1)}
            disabled={historyPage === totalPages}
          />
          <div className="history-item" key={historyItem.billing_no}>
            <div className="claim-header">
              <div className="process-status sm requested">접수</div>
            </div>
            <div className="claim-body">
              <div className="receipt-thumbnail">
                <img src={getBase64Img(historyItem.billing_image)} alt="" />
              </div>
              <div className="claim-info">
                <div className="claim-info-item">
                  <span className="claim-info-item-label">청구 일자: </span>
                  <span className="claim-info-item-value">
                    {format(parseISO(historyItem.billing_dt), 'yyyy/MM/dd')}
                  </span>
                </div>
                <div className="claim-info-item">
                  <span className="claim-info-item-label">접수 번호: </span>
                  <span className="claim-info-item-value">
                    {historyItem.billing_no.slice(0, 8)}
                  </span>
                </div>
                <div className="claim-info-item">
                  <span className="claim-info-item-label">진료비: </span>
                  <span className="claim-info-item-value">
                    {historyItem.med_expns_tot?.toLocaleString() ?? '-'}
                  </span>
                </div>
                <div className="claim-info-item">
                  <span className="claim-info-item-label">지급액: </span>
                  <span className="claim-info-item-value">
                    {historyItem.payment.toLocaleString()}
                  </span>
                </div>
                <div className="claim-info-item">
                  <span className="claim-info-item-label">평균 신뢰도: </span>
                  <span className="claim-info-item-value">
                    {roundPrecision(historyItem.conf_avg, 2)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
