import { parseISO, format } from 'date-fns';
import { BillingDetail } from '@/@types/billing-detail';
import { useImageViewer } from '@/components/image-viewer/ImageViewer';
import { getBase64Img } from '@/utils/parse';
import './ReceiptViewer.scss';
import ZoomInIcon from '@/assets/icons/zoom-in.svg';
import ZoomOutIcon from '@/assets/icons/zoom-out.svg';
import DownloadIcon from '@/assets/icons/download.svg';
import AttachmentIcon from '@/assets/icons/attachment.svg';
import ArrowPrevIcon from '@/assets/icons/arrow-prev.svg';
import ArrowNextIcon from '@/assets/icons/arrow-next.svg';

export function ReceiptViewer(props: { detail: BillingDetail }) {
  const { detail } = props;

  const image = getBase64Img(detail.receipts.image);
  const { viewer, zoomIn, zoomOut } = useImageViewer(image, {
    boundingPolygons: Object.entries(detail.receipts.ocrBoundingPoly)
      .filter(([, p]) => p)
      .map(([label, p]) => ({
        ...p!,
        confidence:
          detail.receipts.ocrText.find((item) => item.label === label)
            ?.confidence ?? 0,
      })),
  });

  return (
    <div className="receipt-view">
      <div className="header">
        <div className="descriptions">
          <div className="item">
            <div className="label">접수 번호: </div>
            <div className="value">{detail.billing_no.slice(0, 8)}</div>
          </div>
          <hr />
          <div className="item">
            <div className="label">청구 일자: </div>
            <div className="value">
              {format(parseISO(detail.billing_dt), 'yyyy.MM.dd')}
            </div>
          </div>
        </div>
        <div className="actions">
          <button type="button" className="download-btn" onClick={() => {}}>
            <DownloadIcon />
          </button>
          <button type="button" className="gallary-btn" onClick={() => {}}>
            <AttachmentIcon />
          </button>
        </div>
      </div>
      <div className="hbox gap(auto) mt(4)">
        <div className="hbox gap(20)">
          <ArrowPrevIcon />
          <div className="hbox font(13/9) 500">
            <div>1</div>
            <div className="total pre c(#888)"> / 1</div>
          </div>
          <ArrowNextIcon />
        </div>
        <div className="zoom-btns hbox gap(10)">
          <button type="button" className="zoom-btn" onClick={zoomOut}>
            <ZoomOutIcon />
          </button>
          <button type="button" className="zoom-btn" onClick={zoomIn}>
            <ZoomInIcon />
          </button>
        </div>
      </div>
      <div className="body">{viewer}</div>
    </div>
  );
}
